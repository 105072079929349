<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'OrganizationHome',
              }"
            >
              組織資料設定
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'UploadFileList',
              }"
              >{{ "檔案列表" }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active
              >{{
                isReadOnly ? "查看檔案" : isEditing ? "編輯檔案" : "新增檔案"
              }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{
                  isReadOnly ? "查看檔案" : isEditing ? "編輯檔案" : "新增檔案"
                }}
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="*File Name"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="v$.uploadFile.name.$error ? false : null"
                    v-model="uploadFile.name"
                    :readonly="isReadOnly"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.uploadFile.name.$error">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="*URL"
                >
                  <button
                    class="
                      btn btn-link
                      px-2
                      text-dark
                      align-self-start
                      upload-button
                    "
                  >
                    <b-form-file @change="upload($event)" />
                    <a
                      v-if="!!uploadFile.file_url"
                      @click="uploadFile.file_url = null"
                      class="btn-sm btn-outline-danger"
                      ><i class="fa fa-trash-o"></i
                    ></a>
                    <a :href="uploadFile.file_url" target="_blank"
                      ><small>
                        目前路徑：{{ uploadFile.file_url || "未上傳" }}
                      </small></a
                    >
                  </button>
                  <b-form-invalid-feedback
                    :state="!v$.uploadFile.file_url.$error"
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="Description"
                >
                  <b-textarea
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="v$.uploadFile.description.$error ? false : null"
                    v-model="uploadFile.description"
                    :readonly="isReadOnly"
                  ></b-textarea>
                  <b-form-invalid-feedback
                    :state="!v$.uploadFile.description.$error"
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="Document Type"
                  v-if="categoryOptions.length > 0"
                >
                  <b-form-select
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="uploadFile.category"
                    :options="categoryOptions"
                    :disabled="isReadOnly"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="Active"
                >
                  <b-form-checkbox
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="uploadFile.is_enabled"
                    :disabled="isReadOnly"
                  ></b-form-checkbox>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="Start Date"
                >
                  <div class="d-flex">
                    <div class="w-50 mr-2">
                      <datepicker
                        v-model="uploadFile.start_at"
                        bootstrap-styling
                        format="yyyy-MM-dd"
                        :language="zh"
                        :disabled="isReadOnly"
                        clear-button
                      ></datepicker>
                    </div>
                    <div class="w-50">
                      <vue-timepicker
                        format="HH:mm"
                        v-model="startTime"
                        :input-class="['form-control']"
                        :disabled="isReadOnly"
                      ></vue-timepicker>
                    </div>
                  </div>
                  <b-form-invalid-feedback
                    :state="!v$.uploadFile.start_at.$error"
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="End Date"
                >
                  <div class="d-flex">
                    <div class="w-50 mr-2">
                      <datepicker
                        v-model="uploadFile.end_at"
                        bootstrap-styling
                        format="yyyy-MM-dd"
                        :language="zh"
                        :disabled="isReadOnly"
                        clear-button
                      ></datepicker>
                    </div>
                    <div class="w-50">
                      <vue-timepicker
                        format="HH:mm"
                        v-model="endTime"
                        :input-class="['form-control']"
                        :disabled="isReadOnly"
                      ></vue-timepicker>
                    </div>
                  </div>
                  <b-form-invalid-feedback
                    :state="!v$.uploadFile.end_at.$error"
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                class="mr-3"
                @click="handleCancel"
                variant="outline-danger"
                >返回
              </b-button>
              <b-button
                v-if="!isReadOnly"
                @click="handleSubmit"
                variant="success"
                >儲存</b-button
              >
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import uploadFileApi from "../../../apis/upload-file";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import VueTimepicker from "vue2-timepicker";
import moment from "moment";
import fileApi from "@/apis/file";

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: { Datepicker, VueTimepicker },
  validations() {
    return {
      uploadFile: {
        org_id: {},
        name: { required },
        description: {},
        is_enabled: {},
        file_url: { required },
        file_id: { required },
        start_at: {},
        end_at: {},
        category:{}
      },
    };
  },

  data() {
    return {
      zh,
      showLoading: false,
      isEditing: true,
      validationErrors: null,
      categoryOptions: [],
      uploadFile: {
        org_id: null,
        name: null,
        description: null,
        is_enabled: true,
        file_id: null,
        file_url: null,
        start_at: null,
        end_at: null,
      },
      file: null,
      showLoadingUpload: false,
      maxUploadSize:10
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),

    startTime: {
      get() {
        return moment(this.uploadFile.start_at).format("HH:mm");
      },
      set(value) {
        if (!value) return
        let [hour, minute] = value.split(":").map(Number);
        this.uploadFile.start_at = moment(this.uploadFile.start_at).set({ hour, minute }).format("YYYY-MM-DD HH:mm");
      },
    },
    endTime: {
      get() {
        return moment(this.uploadFile.end_at).format("HH:mm");
      },
      set(value) {
        if (!value) return
        let [hour, minute] = value.split(":").map(Number);
        this.uploadFile.end_at = moment(this.uploadFile.end_at).set({ hour, minute }).format("YYYY-MM-DD HH:mm");
      },
    },

    isReadOnly() {
      return this.$route.name === "UploadFileView";
    },
  },
  mounted() {
    this.fetchCategoryOptions();
    if (!this.$route.params.upload_file_id) {
      this.isEditing = false;
    } else {
      this.fetchUploadFile();
      this.isEditing = true;
    }
    this.maxUploadSize = this.getModuleConfig("file", "upload_files.max_upload_size") ?? 10
  },
  methods: {
    fetchCategoryOptions() {
      const data = this.dashboardModule?.upload_files?.upload_files_category;
      if (!data) return;
      this.categoryOptions = [
        ...data.sort((a, b) => a.order - b.order).map((source) => {
          return {
            text: source.text,
            value: source.key,
          };
        }),
      ];
    },
    async upload(e) {
      try {
        if (e.target.files[0].size > this.maxUploadSize * 1024 * 1024) {
          this.$swal.fire({
            title: "上傳檔案失敗",
            type: "error",
            text: `檔案大小不可超過${this.maxUploadSize}MB`,
          });
          return;
        }
        this.showLoading = true;
        let { data } = await fileApi.upload(e.target.files[0]);
        this.uploadFile.file_url = data.data.file.url;
        this.uploadFile.file_id = data.data.file.id;
      } catch (error) {
        this.$swal.fire({
          title: "上傳檔案失敗",
          type: "error",
        });
      } finally {
        this.showLoading = false;
      }
    },
    async fetchUploadFile() {
      this.showLoading = true;
      const response = await uploadFileApi.getUploadFile(
        this.organization,
        this.$route.params.upload_file_id
      );
      this.uploadFile = response.data.data;
      this.showLoading = false;
    },
    async handleSubmit() {
      console.log(this.uploadFile)
      if (!this.uploadFile.start_at) this.uploadFile.start_at = null
      if (!this.uploadFile.end_at) this.uploadFile.end_at = null
      const result = await this.v$.$validate();
      if (!result) return;

      try {
        if (this.isEditing) {
          let response = await uploadFileApi.updateUploadFile(
            this.organization,
            this.uploadFile
          );
          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "更新成功",
              type: "success",
            });

            this.$router.push({ name: "UploadFileList" });
          }
        } else {
          let response = await uploadFileApi.storeUploadFile(this.uploadFile);

          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "新增成功",
              type: "success",
            });

            this.$router.push({ name: "UploadFileList" });
          }
        }
      } catch (error) {
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            title: this.isEditing ? "更新失敗" : "新增失敗",
            type: "error",
          });
        }
      }
    },
    handleCancel() {
      this.$router.push({ name: "UploadFileList" });
    },
  },
};
</script>
